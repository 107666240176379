<template>
  <div class="flex justify-center items-center mt-28">
    <img alt="Pagina en construcción" class="w-96" src="../assets/enconstruccion.png" />
  </div>
</template>

<script>
export default {
  name: "HomeView",
};
</script>
